/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import {
  AGGREGATES,
  CUSTOM_OPERATORS,
  Operators,
  OPERATOR_ENUM_TO_OPERATOR_TYPE,
} from 'src/explore/constants';
import { translateToSql } from '../utils/translateToSQL';
import { CLAUSES, EXPRESSION_TYPES } from '../types';

const CUSTOM_OPERATIONS = [...CUSTOM_OPERATORS].map(
  op => OPERATOR_ENUM_TO_OPERATOR_TYPE[op].operation,
);

export default class AdhocFilter {
  constructor(adhocFilter) {
    this.expressionType = adhocFilter.expressionType || EXPRESSION_TYPES.SIMPLE;
    this.column = adhocFilter.column || undefined;
    this.clause = adhocFilter.clause || CLAUSES.WHERE;
    // Current active tab in the filter control
    this.activeTab = adhocFilter.activeTab || undefined;
    // The type of filter currently being edited by the user.
    // If the active tab changes but the user has not made any edits,
    // this value will remain unchanged.
    this.currentEditTab = adhocFilter.currentEditTab || undefined;

    // Cohort filter
    if (this.currentEditTab === EXPRESSION_TYPES.COHORT) {
      this.cohortOperator = adhocFilter.cohortOperator?.toUpperCase();
      this.cohortOperatorId = adhocFilter.cohortOperatorId;
      this.cohortComparator = adhocFilter.cohortComparator;
      this.cohortTopSeries = adhocFilter.cohortTopSeries || undefined
      this.cohortName = adhocFilter.cohortName || undefined;
      this.cohort = adhocFilter.cohort || undefined;
      this.sqlExpression = this.cohort ? translateToSql(adhocFilter) : undefined;
    }

    // Simple filter
    else if (this.currentEditTab === EXPRESSION_TYPES.SIMPLE || this.expressionType === EXPRESSION_TYPES.SIMPLE) {
      this.subject = adhocFilter.subject;
      this.operator = adhocFilter.operator?.toUpperCase();
      this.operatorId = adhocFilter.operatorId;
      this.comparator = adhocFilter.comparator;
      if (
        [Operators.IS_TRUE, Operators.IS_FALSE].indexOf(
          adhocFilter.operatorId,
        ) >= 0
      ) {
        this.comparator = adhocFilter.operatorId === Operators.IS_TRUE;
      }
      if (
        [Operators.IS_NULL, Operators.IS_NOT_NULL].indexOf(
          adhocFilter.operatorId,
        ) >= 0
      ) {
        this.comparator = null;
      }
      this.sqlExpression = null;
    }

    // Custom SQL filter
    else if (this.currentEditTab === EXPRESSION_TYPES.SQL || this.expressionType === EXPRESSION_TYPES.SQL) {
      this.sqlExpression =
        typeof adhocFilter.sqlExpression === 'string'
          ? adhocFilter.sqlExpression
          : translateToSql(adhocFilter, { useSimple: true });

      // If this adhocFilter has operator, it means it's convrted from a simple filter
      if (
        adhocFilter.operator &&
        CUSTOM_OPERATIONS.indexOf(adhocFilter.operator) >= 0
      ) {
        this.subject = adhocFilter.subject;
        this.operator = adhocFilter.operator;
        this.operatorId = adhocFilter.operatorId;
      } else {
        this.subject = null;
        this.operator = null;
      }
    }

    this.isExtra = !!adhocFilter.isExtra;
    this.isNew = !!adhocFilter.isNew;
    this.datasourceWarning = !!adhocFilter.datasourceWarning;

    this.filterOptionName =
      adhocFilter.filterOptionName ||
      `filter_${Math.random().toString(36).substring(2, 15)}_${Math.random()
        .toString(36)
        .substring(2, 15)}`;
    this.filterGroup = adhocFilter.filterGroup || undefined;
    this.savedColumns = adhocFilter.savedColumns || [];
    this.savedCohorts = adhocFilter.savedCohorts || [];
    this.customSqls = adhocFilter.customizedSqls || [];
  }

  translateAdvancedMetricsToSql(
    filterSql,
    params = { useVerboseName: false, transformCountDistinct: false },
  ) {
    if (!filterSql || filterSql === '') {
      return this.translateToSql(params);
    }
    const aggregate = this.aggregate || '';
    let column = '';
    if (this.column && this.column.expression) column = this.column.expression;
    else {
      column =
        params.useVerboseName && this.column?.verbose_name
          ? `${this.column.verbose_name}`
          : this.column?.column_name
            ? `${this.column.column_name}`
            : '';
    }

    if (aggregate === AGGREGATES.COUNT_DISTINCT) {
      return `COUNTIf(DISTINCT ${column}, ${filterSql})`;
    }

    if (aggregate === AGGREGATES.UNIQUE) {
      return `uniqIf(${column}, ${filterSql})`;
    }
    if (aggregate === AGGREGATES.PERCENTILE && this.percentile) {
      const percentage = parseInt(this.percentile.slice(0, -2), 10) / 100;
      return `quantileIf(${percentage})(${column}, ${filterSql})`;
    }
    return `${aggregate}If(${column}, ${filterSql})`;
  }

  duplicateWith(nextFields) {
    return new AdhocFilter({
      ...this,
      // all duplicated fields are not new (i.e. will not open popup automatically)
      isNew: false,
      ...nextFields,
    });
  }

  equals(adhocFilter) {
    return (
      adhocFilter.expressionType === this.expressionType &&
      adhocFilter.sqlExpression === this.sqlExpression &&
      adhocFilter.operator === this.operator &&
      adhocFilter.operatorId === this.operatorId &&
      adhocFilter.comparator === this.comparator &&
      adhocFilter.subject === this.subject
    );
  }

  isValid() {
    const nullCheckOperators = [Operators.IS_NOT_NULL, Operators.IS_NULL].map(
      op => OPERATOR_ENUM_TO_OPERATOR_TYPE[op].operation,
    );
    const truthCheckOperators = [Operators.IS_TRUE, Operators.IS_FALSE].map(
      op => OPERATOR_ENUM_TO_OPERATOR_TYPE[op].operation,
    );
    if (this.activeTab === EXPRESSION_TYPES.COHORT) {
      if (this.column?.has_dimenison) {
        return !!(this.cohortOperator && this.cohortName && this.cohortComparator);
      }
      return !!this.cohortName;
    }
    if (this.activeTab === EXPRESSION_TYPES.SIMPLE || this.expressionType === EXPRESSION_TYPES.SIMPLE) {
      if (nullCheckOperators.indexOf(this.operator) >= 0) {
        return !!(this.operator && this.subject);
      }
      if (truthCheckOperators.indexOf(this.operator) >= 0) {
        return !!(this.subject && this.comparator !== null);
      }
      if (this.operator && this.subject && this.clause) {
        if (Array.isArray(this.comparator)) {
          if (this.comparator.length > 0) {
            // A non-empty array of values ('IN' or 'NOT IN' clauses)
            return true;
          }
        } else if (this.comparator !== null) {
          // A value has been selected or typed
          return true;
        }
      }
    } else if (this.activeTab === EXPRESSION_TYPES.SQL || this.expressionType === EXPRESSION_TYPES.SQL) {
      return !!(this.sqlExpression && this.clause);
    }
    return false;
  }

  getDefaultLabel() {
    const label = translateToSql(this, undefined, true);
    return label.length < 43 ? label : `${label.substring(0, 40)}...`;
  }

  getTooltipTitle() {
    return this.translateToSql();
  }

  translateToSql() {
    return translateToSql(this);
  }
}
