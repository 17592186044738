/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useState } from 'react';
import FormItem from 'src/components/Form/FormItem';
import { Select, AutoComplete, AntdInput } from 'src/components';
import {
  styled,
  SupersetTheme,
  t,
} from '@superset-ui/core';
import 'antd/dist/antd.css';
import {
  Operators,
  MULTI_OPERATORS,
  AGGREGATES,
  OPERATOR_ENUM_TO_OPERATOR_TYPE,
} from 'src/explore/constants';
import FilterDefinitionOption from 'src/explore/components/controls/MetricControl/FilterDefinitionOption';
import AdhocFilter from 'src/explore/components/controls/FilterControl/AdhocFilter';
import { isArray } from 'lodash';
import {
  ColumnMeta,
  Dataset,
} from '@superset-ui/chart-controls';
import { CLAUSES, EXPRESSION_TYPES } from '../types';


export const StyledFormItem = styled(FormItem)`
  &.ant-row.ant-form-item {
    margin: 0;
  }
`;

export interface SimpleExpressionType {
  expressionType: keyof typeof EXPRESSION_TYPES;
  column: ColumnMeta;
  aggregate: keyof typeof AGGREGATES;
  label: string;
}
export interface SQLExpressionType {
  expressionType: keyof typeof EXPRESSION_TYPES;
  sqlExpression: string;
  label: string;
}

export interface MetricColumnType {
  saved_metric_name: string;
}

export interface CohortType {
  expressionType: keyof typeof EXPRESSION_TYPES;
  cohort_name: string;
  aggregate: keyof typeof AGGREGATES;
  sqlExpression: string;
  label: string;
}

export type ColumnType =
  | ColumnMeta
  | SimpleExpressionType
  | SQLExpressionType
  | MetricColumnType
  | CohortType;

export interface Props {
  adhocFilter: AdhocFilter;
  onChange: (filter: AdhocFilter) => void;
  options: ColumnType[];
  datasource: Dataset;
  partitionColumn: string;
  operators?: Operators[];
  validHandler: (isValid: boolean) => void;
  disableFetchFromCache?: boolean
}

export interface AdvancedDataTypesState {
  parsedAdvancedDataType: string;
  advancedDataTypeOperatorList: string[];
  errorMessage: string;
}

interface InValue {
  value: string | number | boolean | null;
  label: string;
}

export const useCohortTabFilterProps = (props: Props) => {

  const { adhocFilter, options } = props;

  // default filter values
  const defaultCohortFilter = {
    currentEditTab: 'COHORT',
    clause: CLAUSES.WHERE,
    expressionType: EXPRESSION_TYPES.SQL,
    subject: undefined,
    column: undefined,
    operator: undefined,
    operatorId: undefined,
    comparator: undefined,
  };

  const onCohortChange = (cohortName: string) => {
    const cohort = options.find(
      option =>
        ('cohort_name' in option && option.cohort_name === cohortName)
    );

    props.onChange(
      props.adhocFilter.duplicateWith({
        cohortName: (cohort as CohortType)?.cohort_name || '',
        cohortOperator: undefined,
        cohortOperatorId: undefined,
        cohortComparator: undefined,
        cohortTopSeries: undefined,
        cohort,
        ...defaultCohortFilter,
      }),
    );
  };

  const onOperatorChange = (cohortOperatorId: Operators) => {
    const currentComparator = adhocFilter.cohortComparator;
    let newComparator;
    // convert between list of comparators and individual comparators
    // (e.g. `in ('North America', 'Africa')` to `== 'North America'`)
    if (MULTI_OPERATORS.has(cohortOperatorId)) {
      newComparator = Array.isArray(currentComparator)
        ? currentComparator
        : [currentComparator].filter(element => element);
    } else {
      newComparator = Array.isArray(currentComparator)
        ? currentComparator[0]
        : currentComparator;
    }
    props.onChange(
      props.adhocFilter.duplicateWith({
        cohortOperatorId,
        cohortOperator: OPERATOR_ENUM_TO_OPERATOR_TYPE[cohortOperatorId].operation,
        cohortComparator: newComparator,
        ...defaultCohortFilter,
      }),
    );
  };

  const onComparatorChange = (cohortComparator: string | string[]) => {
    const option = adhocFilter.cohort.auto_complete_option.find(
      (option: any) =>
        (option?.label === cohortComparator)
    );
    props.onChange(
      props.adhocFilter.duplicateWith({
        cohortComparator,
        cohortTopSeries: option?.top_series,
        ...defaultCohortFilter,
      }),
    );
  };

  const onInComparatorChange = (comparators: any) => {
    const options = adhocFilter.cohort.auto_complete_option;

    const cohortTopSeries = comparators.map((comp: any) => {
      const matchingOptions = options.filter((option: any) => option.label === comp);
      return matchingOptions.map((option: any) => option.top_series);
    });

    props.onChange(
      props.adhocFilter.duplicateWith({
        cohortComparator: comparators,
        cohortTopSeries,
        ...defaultCohortFilter,
      }),
    );
  };

  return {
    onCohortChange,
    onOperatorChange,
    onComparatorChange,
    onInComparatorChange,
  };
};

const AdhocFilterEditPopoverCohortTabContent: React.FC<Props> = props => {
  const {
    onCohortChange,
    onOperatorChange,
    onComparatorChange,
    onInComparatorChange,
  } = useCohortTabFilterProps(props);
  const [suggestions, setSuggestions] = useState<Record<string, any>>([]);
  const [comparator, setComparator] = useState(props.adhocFilter.cohortComparator);

  const renderSubjectOptionLabel = (option: ColumnType | CohortType) => (
    <FilterDefinitionOption option={option} />
  );

  const handleCohortChange = (cohortName: string) => {
    setComparator(undefined);
    onCohortChange(cohortName);
  };
  const { options, adhocFilter } = props;
  let cohorts = options;
  const { cohortName, cohortOperator, cohortOperatorId } = adhocFilter;

  const subjectSelectProps = {
    ariaLabel: t('Select cohort'),
    value: cohortName ?? undefined,
    onChange: handleCohortChange,
    notFoundContent: t(
      "No such cohort found. To filter on a metric, try the Custom SQL tab.",
    ),
    autoFocus: !cohortName,
    placeholder: t('%s cohort(s)', cohorts.length),
  };

  cohorts = options.filter(option => 'cohort_name' in option && option?.cohort_name);

  const singleDimensionOperators = [
    'EQUALS',
    'NOT_EQUALS',
    'LESS_THAN',
    'LESS_THAN_OR_EQUAL',
    'GREATER_THAN',
    'GREATER_THAN_OR_EQUAL',
    'IN',
    'NOT_IN',
    'CONTAINS',
    'CONTAINS_SENSITIVE',
    'STARTS_WITH',
    'NOT_STARTS_WITH',
    'NOT_CONTAINS',
    'NOT_CONTAINS_SENSITIVE',
  ];

  const multipleDimensionsOperators = [
    'EQUALS',
    'NOT_EQUALS',
    'IN',
    'NOT_IN',
  ];

  const operatorSelectProps = {
    placeholder: t(
      '%s operator(s)',
      (adhocFilter.cohort?.dimensions?.length > 1 ? multipleDimensionsOperators : singleDimensionOperators).length,
    ),
    value: cohortOperatorId,
    onChange: onOperatorChange,
    autoFocus: !!subjectSelectProps.value && !cohortOperator,
    ariaLabel: t('Select operator'),
  };

  const handleSearch = (value: string) => {
    onComparatorChange(value);
    setComparator(value);
  };

  const focusInputComparator = (event: React.FocusEvent<HTMLInputElement>) => {
    console.log(event);
    const { cohort } = adhocFilter;
    if (cohort && cohort.has_dimension && cohort.auto_complete_option) {
      setSuggestions(cohort.auto_complete_option?.map((item: any) => item.label) || []);
    } else {
      setSuggestions([])
    }
  };

  const isMultiSelectOperator = () =>
    operatorSelectProps.value === 'IN' ||
    operatorSelectProps.value === 'NOT_IN';

  const multiSelectValueConvert = (comparator: any | any[]) => {
    if (isArray(comparator)) {
      return comparator.map(value => ({ value, label: value }));
    }
    return [{ value: comparator, label: comparator }];
  };

  // Get saved cohorts list
  const subjectComponent = (
    <Select
      css={(theme: SupersetTheme) => ({
        marginTop: theme.gridUnit * 4,
        marginBottom: theme.gridUnit * 4,
      })}
      data-test="select-element"
      options={cohorts.map((cohort: CohortType) => ({
        value: cohort?.cohort_name,
        label: cohort?.cohort_name,
        key: cohort?.cohort_name,
        customLabel: renderSubjectOptionLabel(cohort),
      }))}
      {...subjectSelectProps}
    />
  );

  const getOperatorOptions = (isMultiDimensions: boolean | undefined) =>
    (isMultiDimensions ? multipleDimensionsOperators : singleDimensionOperators)
      .map((option, index) => ({
        value: option,
        label: OPERATOR_ENUM_TO_OPERATOR_TYPE[option].display,
        key: option,
        order: index,
      }))


  const operatorsAndOperandComponent = (
    <>
      <Select
        css={(theme: SupersetTheme) => ({ marginBottom: theme.gridUnit * 4 })}
        options={getOperatorOptions(props.adhocFilter.cohort?.dimensions?.length > 1)}
        {...operatorSelectProps}
      />

      {isMultiSelectOperator() ? (
        <Select
          labelInValue
          css={(theme: SupersetTheme) => ({ marginBottom: theme.gridUnit * 4 })}
          options={suggestions.map((suggestion: string) => ({
            value: suggestion,
            label: String(suggestion),
          }))}
          maxTagCount={7}
          mode="multiple"
          value={comparator ? multiSelectValueConvert(comparator) : []}
          onChange={newValue => {
            if (
              newValue !== null ||
              newValue !== undefined ||
              (Array.isArray(newValue) && (newValue as any[]).length !== 0)
            ) {
              setComparator((newValue as InValue[]).map(value => value.value));
              onInComparatorChange(
                (newValue as InValue[]).map(value => value.value),
              );
            } else {
              setComparator([]);
              onInComparatorChange([]);
            }
          }}
          ariaLabel={t('Input value')}
          filterOption
          onFocus={focusInputComparator}
        />
      ) : (
        <AutoComplete
          style={{ width: '100%' }}
          options={suggestions.map((suggestion: string) => ({
            value: suggestion,
            label: String(suggestion),
          }))}
          onChange={handleSearch}
          onFocus={focusInputComparator}
          value={adhocFilter?.cohortComparator}
            filterOption
        >
          <AntdInput.Search
            size="middle"
            placeholder="Filter value"
          />
        </AutoComplete>
      )}
    </>
  );

  return (
    <>
      {subjectComponent}
      {adhocFilter?.cohort?.has_dimension &&
        operatorsAndOperandComponent}
    </>
  );
};

export default AdhocFilterEditPopoverCohortTabContent;
