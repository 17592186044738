/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import {
  AdhocFilter,
  isFreeFormAdhocFilter,
  isSimpleAdhocFilter,
  SimpleAdhocFilter,
  CohortAdhocFilter,
} from '@superset-ui/core';
import {
  OPERATOR_ENUM_TO_OPERATOR_TYPE,
  Operators,
} from 'src/explore/constants';
import {
  getCohortLable,
  getCohortSQLExpression,
  getSimpleSQLExpression,
} from 'src/explore/exploreUtils';

export const EXPRESSION_TYPES = {
  SIMPLE: 'SIMPLE',
  SQL: 'SQL',
};

export const OPERATORS_TO_SQL = {
  '==': '=',
  '!=': '<>',
  '>': '>',
  '<': '<',
  '>=': '>=',
  '<=': '<=',
  IN: 'IN',
  'NOT IN': 'NOT IN',
  CONTAINS_SENSITIVE: 'LIKE',
  CONTAINS: 'ILIKE',
  NOT_CONTAINS: 'NOT ILIKE',
  NOT_CONTAINS_SENSITIVE: 'NOT LIKE',
  STARTS_WITH: 'ILIKE',
  NOT_STARTS_WITH: 'NOT ILIKE',
  ENDS_WITH: 'ILIKE',
  NOT_ENDS_WITH: 'NOT ILIKE',
  LIKE: 'LIKE',
  ILIKE: 'ILIKE',
  REGEX: 'REGEX',
  'IS NOT NULL': 'IS NOT NULL',
  'IS NULL': 'IS NULL',
  'IS TRUE': 'IS TRUE',
  'IS FALSE': 'IS FALSE',
  'IS EMPTY': "= ''",
  'IS NOT EMPTY': "<> ''",
  // For boolean columns, the null value was converted to -1
  'IS BOOLEAN EMPTY': '= -1',
  'IS BOOLEAN NOT EMPTY': '<> -1',
  'LATEST PARTITION': ({
    datasource,
  }: {
    datasource: { schema: string; datasource_name: string };
  }) =>
    `= '{{ presto.latest_partition('${datasource.schema}.${datasource.datasource_name}') }}'`,
};

export const translateToSql = (
  adhocFilter: AdhocFilter,
  { useSimple }: { useSimple: boolean } = { useSimple: false },
  // showColName: Display the saved column name (true) or detail SQLs(false)
  showColName = false,
) => {
  // Get cohot filter sql expression
  if (adhocFilter?.currentEditTab === 'COHORT') {
    // Show the cohort filter name
    if (showColName) {
      // If the active tab is Cohort, return the simple label "{cohort_name} {operator} {value}""
      // Else if the active tab is SQL, return the whole SQL expression of the cohort
      if (adhocFilter.activeTab !== 'SQL') {
        return getCohortLable(adhocFilter);
      }
      return adhocFilter.sqlExpression;
    }

    // Get the cohort filter SQL expression
    const { cohort, cohortOperator, cohortComparator, cohortTopSeries } =
      adhocFilter as CohortAdhocFilter;
    if (cohort) {
      return getCohortSQLExpression(
        cohort,
        cohortOperator,
        OPERATORS_TO_SQL[cohortOperator],
        cohortTopSeries,
        cohortComparator,
      );
    }
  }
  if (isSimpleAdhocFilter(adhocFilter) || useSimple) {
    let { subject, operator } = adhocFilter as SimpleAdhocFilter;
    const comparator =
      'comparator' in adhocFilter ? adhocFilter.comparator : undefined;
    const column = 'column' in adhocFilter ? adhocFilter.column : undefined;
    let columnType;
    // Only saved columns have expressions
    if (column && (column as any).expression && !showColName) {
      subject = (column as any).expression;
    }
    if (column && (column as any).type) {
      columnType = (column as any).type;
    }
    operator =
      operator &&
      // 'LATEST PARTITION' supported callback only
      operator ===
        OPERATOR_ENUM_TO_OPERATOR_TYPE[Operators.LATEST_PARTITION].operation
        ? OPERATORS_TO_SQL[operator](adhocFilter)
        : OPERATORS_TO_SQL[operator];
    const originOperator = (adhocFilter as SimpleAdhocFilter).operator;

    if (
      originOperator === 'CONTAINS' ||
      originOperator === 'CONTAINS_SENSITIVE'
    ) {
      return getSimpleSQLExpression(
        subject,
        columnType,
        operator,
        comparator,
        '%',
        '%',
      );
    }
    if (
      originOperator === 'NOT_CONTAINS' ||
      originOperator === 'NOT_CONTAINS_SENSITIVE'
    ) {
      return getSimpleSQLExpression(
        subject,
        columnType,
        operator,
        comparator,
        '%',
        '%',
      );
    }
    if (
      originOperator === 'STARTS_WITH' ||
      originOperator === 'NOT_STARTS_WITH'
    ) {
      return getSimpleSQLExpression(
        subject,
        columnType,
        operator,
        comparator,
        '',
        '%',
      );
    }
    if (originOperator === 'ENDS_WITH' || originOperator === 'NOT_ENDS_WITH') {
      return getSimpleSQLExpression(
        subject,
        columnType,
        operator,
        comparator,
        '%',
        '',
      );
    }

    if (operator === undefined) {
      return getSimpleSQLExpression(
        subject,
        columnType,
        originOperator,
        comparator,
      );
    }
    return getSimpleSQLExpression(subject, columnType, operator, comparator);
  }
  if (isFreeFormAdhocFilter(adhocFilter)) {
    return adhocFilter.sqlExpression;
  }
  return '';
};
